<template>
  <div class="forgetPassword">
    <div class="password content_area">
      <div class="tc title">
        <i class="el-icon-back iconBack cursor" @click="back"></i>找回密码
      </div>
      <div class="phoneInput" v-show="isPassWordShow == 1">
        <el-form :rules="phoneRules" ref="phoneForm" :model="passPhone">
          <el-form-item prop="phone">
            <el-input
              placeholder="请输入手机号"
              v-model="passPhone.phone"
              class="input-with-select"
              type="number"
            >
              <el-select
                v-model="passPhone.areaCode"
                slot="prepend"
                popper-class="registerAreaCodeList"
                filterable
                :placeholder="$t('pleaseSelect')"
                :filter-method="areaCodeFilter"
              >
                <el-option
                  v-for="(item, index) in areaCodeList"
                  :key="item.area_code + index + ''"
                  :value="item.area_code"
                  :title="item.country | areacodeTitleFormat(LOCALE)"
                >
                  <p class="area_code">{{ item.area_code }}</p>
                  <p class="country textOverflow">
                    {{ item.country | areacodeAddressFormat(LOCALE) }}
                  </p>
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              :style="{
                width: codeBtnDisabled && codeText ? '250px' : '216px',
              }"
              placeholder="请输入验证码"
              type="text"
              v-model="passPhone.code"
              autocomplete="off"
              nowritten
            ></el-input>
            <el-button
              class="fr"
              :class="{ getCodeBtn: !codeBtnDisabled }"
              @click="getCode"
              :disabled="codeBtnDisabled"
            >
              {{ codeText ? codeText : "获取验证码" }}
              <span v-if="parseInt(codeText) == codeText">&nbsp;s</span>
            </el-button>
            <div class="custom_error_tip" v-show="phoneErrorShow">
              {{ $t("invalidphoneAccount") }}
            </div>
            <div class="custom_error_tip" v-show="phoneDisabledShow">
              {{ $t("accountDisabledTip") }}
            </div>
          </el-form-item>
          <div class="lookEmail cursor fr" @click="isPassWordShow = 2">
            邮箱找回
          </div>
        </el-form>
        <el-button
          class="phoneButton"
          @click="goResetPhone"
          :loading="forgetLoading"
          >确认</el-button
        >
      </div>
      <div class="emailInput" v-show="isPassWordShow == 2">
        <el-form
          :label-position="'top'"
          :model="emailForm"
          ref="emailForm"
          :rules="emailRules"
          v-if="showForm"
        >
          <el-form-item prop="email">
            <el-input
              v-model="emailForm.email"
              size="medium"
              nowritten
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <div class="btns_forget">
            <el-button
              class="phoneButton"
              @click="goResetEmail"
              :loading="forgetEmailLoading"
              >确认发送邮件</el-button
            >
          </div>
        </el-form>
        <div class="active-tip" v-else>
          <div class="clearfix">
            <p class="tip_header fl">{{ $t("resetLinkTip1") }}</p>
            <EmailLogin
              :email="emailForm.email"
              :color="'#ff5155'"
            ></EmailLogin>
          </div>

          <p class="small">
            <span class="dangerColor">*</span>
            {{ $t("activeLinkTip2") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { areaCodeList } from "~/baseUtils/AddressConfig";
import { SSstore } from "~/baseUtils/storage";
import { constName } from "~/baseUtils/constName";
import { confirmEmailLogin } from "~/baseUtils";
import { validateTelephone, validateEmail } from "~/baseUtils/validate";
import EmailLogin from "~/baseComponents/EmailLogin";
export default {
  metaInfo: {
    title: "忘记密码",
  },
  components: {
    EmailLogin,
  },
  data() {
    return {
      areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      passPhone: {
        phone: "",
        areaCode: "+86",
        code: "",
      },
      emailForm: {
        email: "",
      },
      codeText: "",
      codeBtnDisabled: false,
      phoneDisabledShow: false,
      phoneErrorShow: false,
      forgetLoading: false,
      forgetEmailLoading: false,
      showForm: true,
      isPassWordShow: 1,
      user_id: "",
      phoneRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback("手机号格式错误");
            } else {
              try {
                let result = await this.$store.dispatch(
                  "API_user/user_checkIdentifier",
                  { identifier: `+86${value}`, type: 2, source: this.PJSource }
                );
                if (result.success) {
                  this.codeBtnDisabled = true;
                  callback(new Error("该手机号未注册"));
                } else {
                  this.codeBtnDisabled = false;
                  this.user_id = result.user_id;
                  callback();
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
                console.log(e);
              }
            }
          },
          trigger: "blur",
        },
        code: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              if (this.checkCode) {
                let result = await this.$store.dispatch(
                  "baseStore/checkVerifyCode",
                  {
                    area_code: this.passPhone.areaCode.replace("+", ""),
                    mobile: `${this.passPhone.phone}`,
                    ctype: 2,
                    code: this.passPhone.code,
                  }
                );
                this.checkCode = false;
                this.forgetLoading = false;
                if (result.success) {
                  if (result.data) {
                    SSstore.set(
                      constName.siffa.resetPasswordUserId,
                      this.user_id
                    );
                    this.$router.push("/resetPassword");
                    callback();
                  } else {
                    callback(new Error("验证码错误"));
                  }
                } else {
                  callback(new Error(" "));
                  this.$seTip();
                }
              } else {
                callback();
              }
            }
          },
          trigger: "blur",
        },
      },
      emailRules: {
        email: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateEmail(value)) {
              callback(new Error(this.$t("wrongEmailFormat")));
            } else {
              try {
                let result = await this.$store.dispatch(
                  "API_user/user_checkIdentifier",
                  { identifier: value, type: 1, source: this.PJSource }
                );
                if (result.success) {
                  callback(new Error(this.$t("emailNotReg")));
                } else {
                  callback();
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
              }
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    back() {
      if (this.isPassWordShow == 1) {
        this.$router.push({ path: "/sign" });
      } else {
        this.isPassWordShow = 1;
      }
    },
    async getCode() {
      if (this.passPhone.phone == "") {
        this.$message.warning("请先输入手机号");
        return;
      }
      try {
        let result = await this.$store.dispatch("baseStore/getVerifyCode", {
          area_code: "86",
          mobile: `${this.passPhone.phone}`,
          ctype: 2,
          source: this.PJSource,
        });
        if (result.success) {
          this.$message.success(this.$t("vcodeSendSuccess"));
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.codeText = TIME_COUNT;
            this.codeBtnDisabled = true;
            this.timer = setInterval(() => {
              if (this.codeText > 0 && this.codeText <= TIME_COUNT) {
                this.codeText--;
              } else {
                this.codeText = this.$t("getvCode");
                clearInterval(this.timer);
                this.timer = null;
                this.codeBtnDisabled = false;
              }
            }, 1000);
          }
        } else if (result.errorcode == 1015) {
          this.$message.warning("发送频繁，请稍后再试");
        } else {
          //   this.$seTip();
          this.$message.error("系统繁忙");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("系统繁忙");
      }
    },
    goResetPhone() {
      this.checkCode = true;
      this.$refs.phoneForm.validate(async (valid) => {
        if (valid) {
          //   try {
          //     this.checkCode = true;
          //     this.forgetLoading = true;
          //     let result = await this.$store.dispatch(
          //       "baseStore/checkVerifyCode",
          //       {
          //         area_code: "86",
          //         mobile: `${this.passPhone.phone}`,
          //         ctype: 2,
          //         code: this.passPhone.code,
          //       }
          //     );
          //     this.forgetLoading = false;
          //     if (result.success) {
          //       if (result.data) {
          //         SSstore.set(constName.siffa.resetPasswordUserId, this.user_id);
          //         this.$router.push("/resetPassword");
          //       } else {
          //         // this.$message.warning("验证码错误，请重新输入");
          //       }
          //     } else {
          //       this.$seTip();
          //     }
          //   } catch (e) {
          //     this.forgetLoading = false;
          //     console.log(e);
          //   }
        }
      });
    },
    goResetEmail() {
      this.$refs.emailForm.validate(async (valid) => {
        if (valid) {
          this.forgetEmailLoading = true;
          try {
            let result = await this.$store.dispatch("API_user/user_Forget", {
              email: this.emailForm.email,
              source: this.PJSource,
            });
            this.forgetEmailLoading = false;
            if (result.success) {
              this.showForm = false;
            } else {
              this.$seTip();
            }
          } catch (e) {
            this.forgetLoading = false;
            this.$seTip();
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.forgetPassword {
  min-height: 800px;
  background: #ffffff;
  .password {
    width: 340px;
    margin: 0 auto;
    padding-top: 110px;
    .title {
      font-size: 21px;
      font-weight: bold;
    }
    .phoneInput,
    .emailInput {
      margin-top: 40px;
      .lookEmail {
        color: #4a5a81;
        margin: -15px 0 10px;
      }
    }
    .iconBack {
      border: 1px solid #7d8695;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
.phoneButton {
  width: 100%;
  background: #4a5a81;
  color: #fff;
  // &:focus {
  //   // border-color: #ff5155;
  //   // background-color: #fee;
  // }
}
.getCodeBtn {
  color: #4a5a81;
  &:hover,
  &:focus {
    color: #4a5a81;
    // border-color: #ffb9bb;
    // background-color: #fee;
  }
}
.active-tip {
  padding-top: 30px;
}
.tip_header {
  margin: 2px 5px 10px 0;
}
</style>